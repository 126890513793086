<template>
  <div class="wrapper">
      <div class="info_text" >
          <div :class="btnFold?'show_cont':'cont'" ref="info_cont" v-html="showCont"></div>
          <div class="more_box" v-show="textOver" @click="btnFold = !btnFold">
            <img src="@/assets/imgs/cer/more_icon.png" :style="{'transform':btnFold?'rotate(180deg)':'rotate(0deg)','transition':'all 0.1s'}" width="25" alt="">
            <p class="more_text">{{!btnFold?"点击显示全部":"点击收起"}}</p>
          </div>
        </div>
  </div>
</template>
<script>
export default {
  name:'wrap',
  data(){
    return{
      textOver: false, //文本是否超出548px
      btnFold :false
    }
  },
  props:['showCont'],
  mounted(){
    this.$nextTick(() => {
      this.textOver = this.$refs.info_cont.offsetHeight >= 548
    });
  }
}
</script>
<style lang="scss" scoped>
@mixin cont-style{
  font-size: 16px;
        font-weight: 400;
        color: #6D7278;
        line-height: 40px;
        min-height: auto;
}
.wrapper{
  .info_text{
      width: 1200px;
      background: #FFFFFF;
      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      padding: 40px 39px 16px 39px;
      margin-bottom: 49px;
      .cont{
        @include cont-style;
        max-height: 548px;
        overflow-y:hidden;
      }
      .show_cont{
        @include cont-style;
        max-height: auto !important;
      }
      .more_box{
        width: 96px;
        margin: 37px auto 0 auto;
        text-align: center;
         cursor: pointer;
        .more_text{
          margin-top: 15px;
          font-size: 16px;
          font-weight: 400;
          color: #5D5AED;
          line-height: 22px;
         
        }
      }
    }
}
</style>