<template>
  <div class="info_main">
    <a-spin :spinning="loading" :delay="delayTime">
      <div v-if="infoList.length>0">
        <div class="info_item_modul" v-for="item in infoList" :key="item.id">
          <h5 v-text="item.title">认证对象</h5>
          <!-- <div class="info_text" >
            <div class="cont" ref="info_cont" v-html="item.content"></div>
            <div class="more_box" v-show="item.isMore" @click="moreInfo">
              <img src="@/assets/imgs/cer/more_icon.png" width="25" alt="">
              <p class="more_text">点击显示全部</p>
            </div>
          </div> -->
          <m-list-modul :showCont="item.content"/>
      </div>
      </div>
      <div v-else>
        <a-empty description="暂无介绍"/>
      </div>
    </a-spin>
  </div>
</template>
<script>
import {
  queryIntroduce,
} from '@/apis/certificate.js';
import ListModul from './infoListModul.vue';
export default {
  name:"info",
  data(){
    return{
      infoList:[],
      loading:true,
      delayTime: 10,
    }
  },
  components:{
    'm-list-modul':ListModul
  },
  created(){
    this.getInfoData();
  },
  methods:{
    getInfoData(){
      queryIntroduce({terminal:1}).then(res=>{
        console.log(res);
        let msg=res.data;
        if(msg.status==0){
          if(msg.result.terminal==1){
             this.infoList=msg.result.medalGiveLessonsIntroduceBlockList;
             this.$nextTick(()=>{
               this.loading=false;
             });
          }
        }
      })
    },
    
  }
}
</script>
<style lang="scss" scoped>
.info_main{
  width: 1200px;
  margin: 30px auto 0 auto;
  padding-bottom: 50px;
  min-height: 400px;
  max-height: auto;
  .info_item_modul{
    h5{
      position: relative;
      font-size: 28px;
      font-weight: 500;
      color: #333A48;
      line-height: 40px;
      padding-left:17px;
      margin-bottom: 29px;
      &::before {
          position: absolute;
          top: calc(50% - 12px);
          left: 0;
          width: 7px;
          height: 26px;
          background: rgba(116, 101, 255, 1);
          border-radius: 5px;
          content: "";
        }
    }
    // .info_text{
    //   width: 1200px;
    //   background: #FFFFFF;
    //   box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
    //   border-radius: 6px;
    //   padding: 40px 39px 16px 39px;
    //   margin-bottom: 49px;
    //   .cont{
    //     font-size: 16px;
    //     font-weight: 400;
    //     color: #6D7278;
    //     line-height: 40px;
    //     min-height: auto;
    //     max-height: 548px;
    //     overflow-y:hidden;
    //   }
    //   .more_box{
    //     width: 96px;
    //     margin: 37px auto 0 auto;
    //     text-align: center;
    //      cursor: pointer;
    //     .more_text{
    //       margin-top: 15px;
    //       font-size: 16px;
    //       font-weight: 400;
    //       color: #5D5AED;
    //       line-height: 22px;
         
    //     }
    //   }
    // }
  }
}
</style>